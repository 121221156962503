@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

img {
  width: 100%;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.navbar-toggler-icon {
  background-image: url("../src/assets/img/toggler.png") !important;
}

/*/ === home page css starts === /*/

.home-logo {
  height: 60vh;
  padding: 70px 15px;
  background: #151515;
  background: -moz-linear-gradient(
    45deg,
    #151515 0%,
    #3f3f3f 33%,
    #3f3f3f 50%,
    #3f3f3f 72%,
    #000000 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #151515 0%,
    #3f3f3f 33%,
    #3f3f3f 50%,
    #3f3f3f 72%,
    #000000 100%
  );
  background: linear-gradient(
    45deg,
    #151515 0%,
    #3f3f3f 33%,
    #3f3f3f 50%,
    #3f3f3f 72%,
    #000000 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#151515', endColorstr='#000000',GradientType=1 );
}

.home-logo img {
  width: 150px;
  display: block;
  margin: auto;
}

.home-logo .text-logo {
  width: 80%;
}

.home-logo h4 {
  color: #388b9b;
  text-align: center;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.home-slider img {
  height: 100vh;
  object-fit: cover;
}

.home-slider .slider-content {
  position: absolute;
  top: 30%;
  width: 26%;
  left: 12%;
}

.home-slider .slider-content a {
  width: 200px;
  display: block;
  margin: 20px 0px;
}

.home-slider .slider-content img {
  width: 100%;
  height: auto;
}

.home-slider {
  position: relative;
}

.navbar-nav a {
  color: #fff;
  font-family: "Roboto", sans-serif;
  transition: 0.2s ease;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 18px;
}

.navbar-nav a:hover {
  color: rgb(69, 69, 69);
}

.home-slider .reg-btn a {
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  color: #000;
  text-decoration: none;
  position: absolute;
  padding: 13px 35px;
  bottom: 40px;
  left: 110px;
}

.home-slider .social-media-icons {
  display: flex;
  flex-direction: column;
  background-color: #000;
  width: 65px;
  text-align: center;
  padding: 12px 5px 5px;
  position: absolute;
  bottom: 0px;
}

.home-slider .social-media-icons svg {
  fill: #388b9b;
  width: 45px;
  height: 45px;
  border: 1px solid #388b9b;
  border-radius: 50px;
  padding: 10px;
  margin-bottom: 10px;
}

.home-events {
  height: 40vh;
  overflow: hidden;
}

.home-events h4 {
  margin-bottom: 0px;
  color: #388b9b;
  background: #151515;
  background: -moz-linear-gradient(
    45deg,
    #151515 0%,
    #3f3f3f 33%,
    #3f3f3f 50%,
    #3f3f3f 72%,
    #000000 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #151515 0%,
    #3f3f3f 33%,
    #3f3f3f 50%,
    #3f3f3f 72%,
    #000000 100%
  );
  background: linear-gradient(
    45deg,
    #151515 0%,
    #3f3f3f 33%,
    #3f3f3f 50%,
    #3f3f3f 72%,
    #000000 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#151515', endColorstr='#000000',GradientType=1 );
  font-family: "Bebas Neue", cursive;
  font-size: 40px;
  padding: 10px 20px;
}

.sl-section-right {
  position: relative;
}

.sl-section-right button {
  border: none;
}

.sl-section-right .navbar {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 52vh;
}

.sl-section-right .navbar-toggler-icon {
  background-image: url("assets/img/toggle-bar1.svg") !important;
}

.sl-section-right .navbar button {
  box-shadow: none;
}

.offcanvas-end {
  background-color: #000 !important;
}

.offcanvas-header .btn-close {
  background-image: url("assets/img/toggler-close.png") !important;
  opacity: 0.8;
}

.offcanvas-body .nav-link {
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.offcanvas {
  width: 380px !important;
}

/*/ --- home about section css starts --- /*/

.home-about {
  background-color: #fff;
}

/* .home-about .about-img:before {
  content: '';
  display: block;
  width: 30%;
  height: 100%;
  background-color:#181818;
} */

.home-about .custom-container {
  padding: 70px 7%;
}

.home-about .about-content h4 {
  color: #388b9b;
  font-family: "Bebas Neue", cursive;
  font-size: 40px;
}

.home-about .about-content p {
  font-family: "Roboto", sans-serif;
  color: #000;
  font-size: 16px;
  text-align: justify;
}

.read-more-btn {
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #ffffff !important;
  padding: 8px 40px !important;
  display: flex;
  width: -moz-fit-content;
  width: fit-content !important;
  position: relative;
  margin-bottom: 10px;
  background-color: #3c93a1 !important;
  border-radius: 45px !important;
  border: none !important;
}

.home-about .about-img {
  padding: 12%;
}

/*/ --- home about section css ends --- /*/

/*/ --- home events section css starts --- /*/
.home-events-main {
  background-color: #aadce3;
}
.home-events-main .events-banner {
  background-color: #aadce3;
  padding: 85px 20px 20px !important;
}

.home-events-main .events-banner h4 {
  color: #388b9b;
  font-family: "Bebas Neue", cursive;
  font-size: 40px;
  text-align: center;
}

.home-events-main .events-list .custom-container {
  padding: 40px 8%;
}

.home-events-main .events-list .row {
  justify-content: center;
}

.home-events-main .events-list .events-box {
  position: relative;
}

.home-events-main .events-list .events-box img {
  height: 380px;
  object-fit: cover;
  margin-bottom: 10px;
}

.home-events-main .events-list .events-box h6 {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  font-weight: 500;
  color: #2a2a2a;
  padding: 10px 20px;
  position: absolute;
  top: 330px;
  left: 22px;
}

.home-events-main .events-list .events-box p {
  font-family: "Roboto", sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
  margin-top: 20px;
}

.home-events-main .events-list .event-btn {
  border: none;
  margin: 40px 0px 20px;
}

.home-events-main .read-more-btn {
  margin: auto;
}

/*/ --- home events section css ends --- /*/

/*/ --- home committee section css starts --- /*/
.home-committee {
  background-color: #fff;
}

.home-committee .custom-container {
  padding: 40px 8%;
}

.home-committee .member-box {
  padding: 10px !important;
}

.home-committee h4 {
  color: #388b9b;
  font-family: "Bebas Neue", cursive;
  font-size: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.home-committee .committee-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.home-committee .member-box {
  width: 20%;
  padding: 20px;
}

.home-committee .member-box img {
  height: 225px;
  object-fit: cover;
}

.home-committee .member-box h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #388b9b;
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
}

.home-committee .member-box p {
  font-family: "Roboto", sans-serif;
  color: #303030;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
}
/*/ --- home committee section css ends --- /*/

/*/ --- home service css starts --- /*/
.home-service {
  background: #fff;
  background: linear-gradient(
    90deg,
    #50bfc9 0%,
    #50bfc9 35%,
    rgb(255 255 255) 35%,
    rgb(255 255 255) 100%
  );
}

.home-service .custom-container {
  padding: 40px 8%;
}

.home-service .service-content {
  background-color: #a9dbe2;
  padding: 75px 9%;
  height: 100%;
}

.home-service .service-content h4 {
  color: #388b9b;
  font-family: "Bebas Neue", cursive;
  font-size: 40px;
}

.home-service .service-content p {
  font-family: "Roboto", sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
  margin-top: 20px;
}

.home-service .service-img img {
  height: 440px;
  object-fit: cover;
}
/*/ --- home service css ends --- /*/

/*/ --- home form css starts --- /*/
.home-register {
  background: rgb(24, 24, 24);
  background: linear-gradient(
    90deg,
    rgba(24, 24, 24, 1) 0%,
    rgba(24, 24, 24, 1) 35%,
    rgba(0, 0, 0, 1) 35%,
    rgba(0, 0, 0, 1) 100%
  );
}

.home-register .custom-container {
  padding: 40px 8% 120px;
}

.home-register:after {
  content: "";
  display: block;
  width: 100%;
  height: 125px;
  background-color: #000;
}

.home-register .form-box {
  padding-left: 60px;
}

.home-register .form-box h4 {
  color: #388b9b;
  font-family: "Bebas Neue", cursive;
  font-size: 40px;
  text-align: left;
  margin-bottom: 20px;
}

.home-register .form-box a {
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  padding: 21px 35px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  font-size: 20px;
  margin-bottom: 10px;
}

.home-register .form-box a:after {
  content: "";
  display: block;
  background-image: url("assets/img/btn-shape.png");
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.home-register .form-box p {
  font-family: "Roboto", sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
  margin-top: 20px;
}

.home-register .form-img img {
  height: 370px;
  object-fit: cover;
}

.home-register .form-box input {
  border: 2px solid #181818;
  font-family: "Roboto", sans-serif;
  background-color: #000000;
  border-radius: 0px;
  height: 55px;
  margin-bottom: 20px;
  color: #7e7e7e;
}

.home-register .form-box input:focus-within {
  box-shadow: none;
}

.home-register .form-box input[type="textarea"] {
  height: 175px;
  overflow-y: scroll;
}

.home-register .form-box::placeholder {
  font-family: "Roboto", sans-serif;
  color: #2e2e2e !important;
  font-size: 14px;
}

.home-register .form-box button {
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  padding: 16px 44px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  background-color: #000;
  border: none;
  margin-left: 12px;
}

.home-register .form-box button:after {
  content: "";
  display: block;
  background-image: url("assets/img/btn-shape.png");
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
/*/ --- home form css ends --- /*/

/*/ --- footer css starts --- /*/
.footer {
  background-color: #388b9b;
  margin-top: 40px;
}

.footer .custom-container {
  padding: 40px 8%;
}

.footer .footer-logo {
  width: 134px;
  display: block;
  margin: 0px auto 0px;
}

.footer .footer-nav {
  text-align: center;
  padding: 30px 0px 20px;
}

.footer .footer-nav a {
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 14px;
  border-right: 1px solid #a3a3a3;
  font-weight: 300;
  padding: 0px 35px;
}

.footer .footer-nav a:last-child {
  border-right: none;
}

.footer .copyright {
  padding: 30px 7%;
  background-color: #0d0d0d52;
}

.footer .copyright p {
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
}

.footer .copyright a {
  color: #fff;
}

.footer .copyright .ft-right p {
  text-align: right;
}

/*/ --- footer css ends --- /*/

/*/ === home page css ends === /*/

/*/ === inner navbar css starts === /*/
.inner-navbar {
  background: none;
  position: fixed !important;
  z-index: 999;
  width: 100%;
  padding: 10px 7% !important;
}

.nav-reg-btn {
  background-color: #fff;
  color: #2ea2b0 !important;
  border-radius: 40px;
}

.navbar-fixed-top.scrolled-inner .navbar-nav .nav-reg-btn {
  background-color: #2ea2b0 !important;
  color: #fff !important;
}

.navbar-fixed-top.scrolled-inner {
  background-color: #ffffff !important;
  transition: background-color 200ms linear;
  -webkit-box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  position: fixed;
  width: 100%;
  z-index: 99;
}

.navbar-fixed-top.scrolled-inner .navbar-nav a {
  color: #000;
}

.inner-navbar .navbar-nav a {
  color: #fff;
  font-family: "Roboto", sans-serif;
  transition: 0.2s ease;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
}

.inner-navbar .navbar-brand {
  width: 100px;
  transition: 0.2s ease-in;
}

.navbar-fixed-top.scrolled-inner .navbar-brand {
  width: 70px;
}

.inner-navbar .navbar-nav {
  margin-left: auto;
  margin-right: 0px !important;
}

/*/ === inner navbar css ends === /*/

/*/ === about page css starts === /*/
.about-banner {
  padding: 0px 0% 0px !important;
  background-color: #fff;
}

.about-banner .banner-bg {
  background-image: url("assets/img/about-banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-banner .banner-bg h1 {
  font-family: "Bebas Neue", cursive;
  text-align: center;
  color: #fff;
  padding: 120px 20px 100px;
  font-size: 90px;
}

.main-about {
  background-color: #fff;
}

.main-about .custom-container {
  padding: 40px 7%;
}

.main-about .about-img img {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.main-about .about-img {
  padding: 0px 8%;
}

.main-about .about-content {
  padding-right: 8%;
}

.main-about .about-content h6 {
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 22px;
}

.main-about .about-content h4 {
  color: #388b9b;
  font-family: "Bebas Neue", cursive;
  font-size: 50px;
}

.main-about .mission-box-content h6 {
  color: #388b9b;
  font-family: "Bebas Neue", cursive;
  font-size: 28px;
}

.main-about .about-content p {
  font-family: "Roboto", sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
}

.main-about .mission-box-content p {
  font-family: "Roboto", sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
}

.main-about .mission-box-content {
  padding: 50px 0px 20px;
}

.main-vision-mission .custom-container {
  padding: 40px 7% 90px;
}

.main-vision-mission .cap-box h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 60px;
  color: #ececec;
}

.main-vision-mission .cap-box h1:before {
  content: "";
  display: block;
  width: 2px;
  height: 110px;
  background-color: #5a5a5a;
}

.main-vision-mission .content-box h4 {
  font-family: "Bebas Neue", cursive;
  color: #aeaeae;
  font-size: 24px;
}

.main-vision-mission .content-box p {
  font-family: "Roboto", sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
}

.main-vision-mission .content-box {
  padding: 30px 6%;
  border-bottom: 1px solid #181818;
}

.main-vision-mission .content-box:last-child {
  border-bottom: 0px;
}
/*/ === about page css ends === /*/

/*/ === events page css starts === /*/
.events-banner {
  padding: 0px 0% 50px !important;
}

.events-banner .banner-bg {
  background-image: url("assets/img/about-banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.events-banner .banner-bg h1 {
  font-family: "Bebas Neue", cursive;
  text-align: center;
  color: #fff;
  padding: 120px 20px 100px;
  font-size: 90px;
}

.main-events .custom-container {
  padding: 40px 10%;
}

.main-events .event-box .event-img img {
  height: 350px;
  object-fit: cover;
}

.main-events .event-box .event-content {
  padding: 20px;
}

.main-events .event-box .event-content h6 {
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-weight: 300;
  font-size: 14px;
  position: relative;
}

.main-events .event-box .event-content h6:before {
  content: "";
  display: block;
  width: 100px;
  height: 0.5px;
  background-color: #868686;
  position: absolute;
  left: -107px;
  top: 7px;
}

.main-events .event-box .event-content h4 {
  font-family: "Bebas Neue", cursive;
  color: #43a2b1;
  font-weight: 300;
  font-size: 30px;
  margin: 30px 0px;
}

.main-events .event-box .event-content p {
  font-family: "Roboto", sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: left;
}

.main-events .event-box .event-content a {
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #43a2b1;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.main-events .event-box {
  margin-bottom: 60px;
}

.main-events .event-box .event-content a:hover svg {
  margin-left: 10px;
  transition: 0.3s ease-in;
}

/*/ --- events box two starts --- /*/

.main-events .event-box-two .event-img img {
  height: 350px;
  object-fit: cover;
}

.main-events .event-box-two .event-content {
  padding: 20px;
}

.main-events .event-box-two .event-content h6 {
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-weight: 300;
  font-size: 14px;
  position: relative;
  text-align: right;
}

.main-events .event-box-two .event-content h6:before {
  content: "";
  display: block;
  width: 100px;
  height: 0.5px;
  background-color: #868686;
  position: absolute;
  right: -107px;
  top: 7px;
}

.main-events .event-box-two .event-content h4 {
  font-family: "Bebas Neue", cursive;
  color: #fff;
  font-weight: 300;
  font-size: 30px;
  margin: 30px 0px;
  text-align: right;
}

.main-events .event-box-two .event-content p {
  font-family: "Roboto", sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: right;
}

.main-events .event-box-two .event-content a {
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #43a2b1;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin-left: auto;
}

.main-events .event-box-two {
  margin-bottom: 60px;
}

.main-events .event-box-two .event-content a:hover svg {
  margin-right: 10px;
  transition: 0.3s ease-in;
}

/*/ --- events box two ends --- /*/

/*/ === events page css ends === /*/

/*/ === events detail page css starts === /*/
.events-detail-main {
  background-color: #ffffff;
}

.events-detail-main .custom-container {
  padding: 40px 7%;
}

.events-detail-main .event-content {
  padding-top: 70px;
}

.events-detail-main .event-content h4 {
  color: #313131;
  font-family: "Bebas Neue", cursive;
  font-size: 50px;
}

.events-detail-main .event-content p {
  font-family: "Roboto", sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
}

.events-detail-main .event-date {
  padding-top: 70px;
}

.events-detail-main .event-date h6 {
  font-family: "Roboto", sans-serif;
  color: #545454;
  font-weight: 300;
  font-size: 16px;
}

.events-detail-main .event-date h4 {
  font-family: "Roboto", sans-serif;
  color: #000000;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
}

.events-detail-main .event-img {
  padding: 40px 0px;
}

.events-detail-main .reg-btn a {
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #ffffff !important;
  padding: 8px 40px !important;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin-bottom: 10px;
  background-color: #3c93a1 !important;
  border-radius: 45px !important;
  border: none !important;
}
/*/ === events detail page css ends === /*/

/*/ === members details page css starts === /*/
.member-banner {
  padding: 0px 0% 50px !important;
}

.member-banner .banner-bg {
  background-image: url("assets/img/about-banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.member-banner .banner-bg h1 {
  font-family: "Bebas Neue", cursive;
  text-align: center;
  color: #fff;
  padding: 120px 20px 100px;
  font-size: 90px;
}

.members-main .custom-container {
  padding: 40px 8%;
}

.members-main h4 {
  color: #388b9b;
  font-family: "Bebas Neue", cursive;
  font-size: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.members-main .committee-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.members-main .member-box {
  width: 20%;
  padding: 20px;
}

.members-main .member-box img {
  height: 225px;
  object-fit: cover;
}

.members-main .member-box h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
}

.members-main .member-box p {
  font-family: "Roboto", sans-serif;
  color: #303030;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
}

.members-main .other-members-list li {
  font-family: "Roboto", sans-serif;
  color: #5d5d5d;
  font-size: 18px;
  list-style: none;
  margin-bottom: 10px;
  border-bottom: 1px solid #151515;
  padding: 15px 0px;
}

.members-main .other-members-list ul {
  padding-left: 0px;
}
/*/ === members details page css ends === /*/

/*/ === gallery page css starts === /*/

.gallery-main .custom-container {
  padding: 40px 8%;
}

.gallery-main .gallery-album-box img {
  height: 450px;
  object-fit: cover;
  transition: 0.5s ease-in;
}

.gallery-main .gallery-album-box img:hover {
  transform: scale(1.2);
}

.gallery-main .gallery-album-box {
  padding: 30px 20px;
}

.gallery-main .gallery-album-box .inner-box {
  overflow: hidden;
}

.gallery-main .gallery-album-box .inner-box {
  position: relative;
}

.gallery-main .gallery-album-box .inner-box h4 {
  position: absolute;
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-weight: 300;
  position: absolute;
  padding: 60px 20px 20px;
  position: absolute;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 16%,
    rgba(0, 0, 0, 0) 100%
  );
  bottom: 0px;
  margin-bottom: 0px;
}
/*/ === gallery page css ends === /*/

/*/ === gallery detail page css starts === /*/
.gallery-detail-main .custom-container {
  padding: 40px 8% 80px;
}

.gallery-detail-main .gallery-detail-box img {
  height: 450px;
  object-fit: cover;
  transition: 0.5s ease-in;
}

.gallery-detail-main .gallery-detail-box img:hover {
  filter: grayscale(1);
}

.gallery-detail-main .__react_modal_image__modal_content img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.gallery-detail-main .__react_modal_image__icon_menu a:first-child {
  display: none;
}
/*/ === gallery detail page css ends === /*/

/*/ === contact page css starts === /*/
.contact-main .custom-container {
  padding: 40px 8% 140px;
}

.contact-main .address-box h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #3b909e;
  font-size: 16px;
}

.contact-main .address-box a {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #000;
  font-size: 18px;
  display: block;
  margin-bottom: 0px;
}

.contact-main .address-box p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #000;
  font-size: 18px;
  margin-bottom: 0px;
}

.contact-main .address-box {
  padding: 60px 45px;
}

.contact-main .form-box h4 {
  color: #fff;
  font-family: "Bebas Neue", cursive;
  font-size: 25px;
  text-align: left;
  margin-bottom: 20px;
}

.contact-main .form-box input {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  border-radius: 40px;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
}

.contact-main .form-box input:focus-within {
  box-shadow: none;
}

.contact-main .form-box input[type="textarea"] {
  height: 175px;
  overflow-y: scroll;
}

.contact-main .form-box::placeholder {
  font-family: "Roboto", sans-serif;
  color: #2e2e2e !important;
  font-size: 14px;
}

.contact-main .form-box button:hover {
  background-color: #000;
}

.contact-main .location-map iframe {
  width: 100%;
  height: 100%;
  filter: grayscale(1);
}

.contact-main .location-map {
  height: 100%;
  margin-top: 60px;
}
/*/ === contact page css ends === /*/

/*/ === member registration css starts === /*/
.member-registration-main .custom-container {
  padding: 40px 8% 100px;
}

.member-registration-main .form-box input {
  /* border: 1px solid #e7e7e7;
    font-family: 'Roboto', sans-serif;
    background-color: #eeeeee;
    height: 55px;
    margin-bottom: 20px;
    color: #3a3a3a;
    border-radius: 50px;
    width: 100%; */
}

.member-registration-main .form-box button:hover {
  background-color: #000;
}

.member-registration-main .form-box .fileinput {
  width: 200px;
  margin: auto;
  position: relative;
  border: 2px solid #181818;
  padding: 25px;
  margin-bottom: 15px;
}

.member-registration-main .form-box .fileinput input {
  width: 199px;
  height: 169px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.member-registration-main .form-box .fileinput p {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #7e7e7e;
}

.member-registration-main .form-box .fileinput img {
  width: 60px;
  margin: auto;
  display: block;
}

.member-registration-main .read-more-btn {
  margin: auto;
  display: block;
}

.member-registration-main .react-dropdown-select {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
  border-radius: 50px;
}

.member-registration-main .react-dropdown-select input {
  margin-bottom: 0px;
  border: none;
  background: none;
  font-size: 16px;
}

.member-registration-main label {
  display: block;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #2e2e2e;
  margin-bottom: 6px;
}

.member-registration-main .form-box input[type="text"] {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
  border-radius: 50px;
}

.member-registration-main .form-box input[type="tel"] {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
  border-radius: 50px;
}

.member-registration-main .form-box input[type="email"] {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
  border-radius: 50px;
}

.member-registration-main .form-box input[type="file"] {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
  border-radius: 50px;
}

.member-registration-main .form-box select {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
  border-radius: 50px;
  width: 100%;
}

/*/ === member registration css ends === /*/

/*/ === event registration css starts === /*/

.event-register-main .custom-container {
  padding: 40px 8% 80px;
}

.event-register-main .member-box h4 {
  color: #43a2b1;
  font-family: "Bebas Neue", cursive;
  font-size: 38px;
  text-align: left;
  margin-bottom: 5px;
}

.event-register-main .member-box h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #43a2b1;
  font-size: 18px;
  margin-bottom: 50px;
}

.event-register-main .member-box input {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
  border-radius: 50px;
  width: 100%;
}

.event-register-main .member-box select {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
  border-radius: 50px;
  width: 100%;
}

.event-register-main .member-box .react-dropdown-select {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
  border-radius: 50px;
}

.event-register-main .member-box .react-dropdown-select input {
  margin-bottom: 0px;
  border: none;
  background: none;
  font-size: 16px;
}

.event-register-main .member-box label {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #2e2e2e;
  margin-bottom: 6px;
}

.event-register-main .member-box button:hover {
  background-color: #000;
}

.event-register-main .member-box p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  margin-bottom: 50px;
  margin-top: 20px;
}

.event-register-main .member-box a {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  margin-bottom: 50px;
  margin-top: 20px;
  text-decoration: underline !important;
}

.event-register-main .or-div p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  font-size: 16px;
  margin-bottom: 50px;
  margin-top: 20px;
  text-align: center;
  position: relative;
}

.event-register-main .or-div p:before {
  content: "";
  display: block;
  width: 1px;
  height: 100px;
  background-color: #454545;
  position: absolute;
  margin: auto;
  left: 0px;
  right: 0px;
  bottom: 35px;
}

.event-register-main .or-div p:after {
  content: "";
  display: block;
  width: 1px;
  height: 100px;
  background-color: #454545;
  position: absolute;
  margin: auto;
  left: 0px;
  right: 0px;
  top: 35px;
}

.event-register-main .guest-register-box h4 {
  color: #43a2b1;
  font-family: "Bebas Neue", cursive;
  font-size: 38px;
  text-align: left;
  margin-bottom: 5px;
}

.event-register-main .guest-register-box h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #43a2b1;
  font-size: 18px;
  margin-bottom: 50px;
}

.event-register-main .guest-register-box input {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
  border-radius: 50px;
  width: 100%;
}

.event-register-main .guest-register-box label {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: 16px;
  color: #747474;
  margin-bottom: 6px;
}

.event-register-main .guest-register-box button:hover {
  background-color: #000;
}

.event-register-main form .read-more-btn {
  margin: auto;
  display: block;
}

.man-field {
  color: red;
  font-size: 14px;
}

.event-register-main .form-otp-box .read-more-btn {
  margin: auto;
  display: block !important;
}

.event-register-main .form-otp-box {
  margin-top: 15px;
}

.event-register-main .form-otp-box p {
  text-align: center;
  font-size: 15px;
  margin-top: 9px;
  font-weight: 500;
}

.member-registration-main .form-otp-box p {
  text-align: center;
  font-size: 15px;
  margin-top: 9px;
  font-weight: 500;
}

.event-register-main .form-otp-box input {
  width: 100%;
}

.spinner-border {
  display: block !important;
  margin: auto;
}

.member-registration-main .form-otp-box input[type="text"] {
  border: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
  background-color: #eeeeee;
  height: 55px;
  margin-bottom: 20px;
  color: #3a3a3a;
  border-radius: 50px;
  width: 100%;
  margin-top: 15px;
}

.guest-register-box .member-reg-box {
  padding: 30px 0px;
  border-top: 1px solid #bcbcbc;
  margin-top: 30px;
}

.guest-register-box .member-reg-box a {
  background-color: #227683;
  border-radius: 40px;
  text-align: center;
  color: #fff;
  padding: 8px 10px;
  display: block;
  width: 100%;
}

.guest-register-box .member-reg-box h5 {
  text-align: center;
  font-size: 16px;
  margin: 15px 0px;
}
.PhoneInputCountry {
  height: 55px;
}

.success-main .custom-container {
  padding: 200px 7% 100px;
}

.success-main img {
  width: 155px;
  display: block;
  margin: auto;
}

.success-main h4 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: center;
  color: #000;
  font-size: 28px;
  margin-top: 20px;
}

.success-main h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-align: center;
  color: #000;
}

.success-main a {
  display: block;
  width: fit-content;
  margin: auto;
}

/*/ === event registration css ends === /*/

/*/ --- stall page css starts --- /*/
.stall-layout-main .custom-container {
  padding: 40px 7%;
}

.stall-layout-main .stall-main-area .row1 {
  display: flex;
  justify-content: space-between;
}

.stall-layout-main .stall-box {
  width: 11.1%;
  height: 100px;
  background-color: #ffffff;
  /* border: 1px solid #000; */
}

.stall-layout-main .layout-box {
  border: 2px solid #2d2d2d;
}

.stall-layout-main .yellow-box {
  background-color: yellow;
  border: 1px solid #2d2d2d;
}

.stall-layout-main .red-box {
  background-color: red;
  border: 1px solid #2d2d2d;
}

.stall-layout-main .green-box {
  background-color: green;
  border: 1px solid #2d2d2d;
}

.stall-layout-main .blue-box {
  background-color: blue;
  border: 1px solid #2d2d2d;
}

.stall-layout-main .stage-area {
  display: flex;
}

.stall-layout-main .stage-area .exit {
  width: 30%;
  height: 100px;
}

.stall-layout-main .stage-area .stage {
  width: 70%;
  height: 100px;
  background-color: #7b74b7;
}

.stall-layout-main .audience-area {
  display: flex;
  margin-bottom: 30px;
}

.stall-layout-main .a-stall-box {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
}

.stall-layout-main .a-stall-box .stall-box {
  width: 50%;
  height: 100px;
}

.stall-layout-main .audience-box {
  background-color: #73b0b5;
  width: 70%;
}

.stall-layout-main .audience-box h6 {
  color: #fff;
  text-align: center;
  padding-top: 60px;
}

.stall-layout-main .stage-area .exit h6 {
  text-align: center;
  background: red;
  width: fit-content;
  margin: auto;
  padding: 3px 21px;
}

.stall-layout-main .stall-box.entry {
  padding-top: 70px;
}

.stall-layout-main .stall-box.entry h6 {
  text-align: center;
  background: red;
  width: fit-content;
  margin: auto;
  padding: 3px 21px;
  color: #fff;
}

.stall-layout-main .stall-box p {
  color: #fff;
  text-align: center;
  padding-top: 40px;
  font-weight: 500;
}

.stall-layout-main .booked {
  background-color: #4f4f4f;
}

.stall-layout-main .stall-box.yellow-box p {
  color: #000;
  font-weight: 500;
}

.stall-layout-main .stage-area .stage h6 {
  margin: auto;
  text-align: center;
  padding-top: 40px;
  color: #fff;
}

/*/ --- stall page css ends --- /*/

.searchWrapper {
  padding: 0px !important;
  border: none !important;
}

@media only screen and (max-width: 767.5px) {
  .home-committee .member-box {
    width: 30%;
    margin: auto;
  }
  .main-events .event-box .event-content h6:before {
    width: 80px;
    left: 0px;
  }
  .main-events .event-box .event-content h6 {
    padding-left: 90px;
  }
  .main-events .event-box-two .event-content h6 {
    padding-right: 90px;
  }
  .main-events .event-box-two .event-content h6:before {
    width: 80px;
    right: 0px;
  }
  .footer-nav {
    display: flex;
    flex-direction: column;
  }
  .footer .footer-nav a {
    padding: 8px 35px;
  }
  .footer .custom-container {
    padding: 40px 8% 20px;
  }
  .events-banner .banner-bg h1 {
    padding: 100px 20px;
  }
  .main-events .custom-container {
    padding: 40px 3%;
  }
  .main-vision-mission .content-box {
    padding: 30px 0px;
  }
  .navbar-collapse {
    position: absolute;
    background-color: #ffffff;
    top: 125px;
    width: 85%;
    border-bottom: 1px solid #5d5d5d;
  }
  .inner-navbar .navbar-nav a {
    color: #000;
  }
  .home-register .form-box {
    padding-left: 0px;
  }
  .event-register-main .or-div p:before {
    bottom: -38px;
    transform: rotate(90deg);
    left: -145px;
  }
  .event-register-main .or-div p:after {
    right: -143px;
    top: -38px;
    transform: rotate(90deg);
  }
  .home-slider img {
    height: 50vh;
    object-position: 65%;
  }
  .home-slider .slider-content {
    top: 38%;
    width: 36%;
    left: 12%;
  }
  .home-logo {
    height: auto;
  }
  div.home-logo-res {
    order: 1;
  }
  div.home-slider-res {
    order: 2;
  }

  .sl-section-right .navbar {
    top: 30px;
    right: 50px;
  }
  .footer .footer-nav a {
    border-right: none;
  }
  .members-main .committee-row {
    justify-content: center;
  }
  .members-main .member-box {
    width: 45%;
  }
  .footer .copyright p {
    text-align: center;
  }
  .footer .copyright .ft-right p {
    text-align: center;
  }
  .member-banner .banner-bg h1 {
    padding: 100px 20px;
    font-size: 45px;
  }
  .about-banner .banner-bg h1 {
    padding: 100px 20px;
    font-size: 45px;
  }
  .home-service .service-content {
    padding: 75px 3%;
  }
  .home-events-main .events-list .custom-container {
    padding: 40px 10%;
  }
  .home-service .service-img {
    padding: 0px 2%;
  }
  .home-register .custom-container {
    padding: 40px 7% 120px;
  }
  div.hm-service-second {
    order: 1;
  }
  div.hm-service-first {
    order: 2;
  }
  .home-committee .custom-container {
    padding: 40px 5%;
  }
  .home-committee .member-box img {
    height: 200px;
  }
  .members-main .custom-container {
    padding: 40px 0%;
  }
  .home-committee .member-box img {
    height: 200px;
  }
  .members-main .member-box {
    padding: 10px !important;
  }
  .stall-layout-main .custom-container {
    padding: 40px 2%;
  }
  .stall-layout-main .stall-box {
    width: 4%;
  }
  .stall-layout-main .red-box {
    width: 15%;
  }
  .stall-layout-main .yellow-box {
    width: 15%;
  }
  .stall-layout-main .red-box {
    width: 15%;
  }
  .stall-layout-main .green-box {
    width: 15%;
  }
  .stall-layout-main .entry {
    width: 15%;
  }
}

@media only screen and (max-width: 499px) {
  .home-committee .member-box {
    width: 50%;
    margin: auto;
  }
  .sl-section-right .navbar {
    top: 30px;
  }
}
